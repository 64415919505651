import styled from '@emotion/styled';
import { CSSProperties } from 'react';

import { Colors, Typography } from '@coco/ui-web';

import { LID_ACTIONS, ParkingLotRobot } from '../@types';
import { useToggleRobotLid, useWindowDimensions } from '../hooks';
import { Battery } from './Battery';
import Loader from './Loader';
import { Card } from './shared';
import Button from './shared/Button';

export const RobotContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
  },
  ({ style }) => ({ ...style })
);

export const RobotSerialCopy = styled.span`
  width: 130px;
`;

export const RobotStateCopy = styled.span`
  color: ${Colors.grey500};
  text-transform: capitalize;
`;

export const RobotButton = ({
  onClick,
  style,
  title,
}: {
  onClick?: () => void;
  style?: CSSProperties;
  title: string;
}) => (
  <Button
    className={Typography.LABEL_XL}
    onClick={onClick}
    style={{ backgroundColor: Colors.bluegrey300, color: Colors.black, height: 42, width: 130, ...style }}
  >
    {title}
  </Button>
);

export const RobotCard = ({ robot }: { robot: ParkingLotRobot }) => {
  const { isSmall } = useWindowDimensions();
  const containerStyle: CSSProperties = { flexDirection: isSmall ? 'column' : 'row' };

  const { mutate: toggleLid, isLoading } = useToggleRobotLid(robot.serial);

  return (
    <Card key={robot.serial} style={{ height: isSmall ? 100 : undefined }}>
      <div>
        <RobotContainer style={containerStyle}>
          <RobotSerialCopy className={Typography.DISPLAY_XS_MED}>{robot.serial}</RobotSerialCopy>
          <Battery battery={robot.battery.currentBatteryPercentage} serial={robot.serial} />
        </RobotContainer>
        <RobotStateCopy className={Typography.BODY_MD}>{robot.robotState.robotState.toLowerCase()}</RobotStateCopy>
      </div>
      <RobotContainer style={containerStyle}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <RobotButton
              onClick={() => toggleLid(LID_ACTIONS.OPEN)}
              style={{ marginRight: 10, marginBottom: isSmall ? 10 : undefined }}
              title="Open Lid"
            />
            <RobotButton onClick={() => toggleLid(LID_ACTIONS.CLOSE)} title="Close Lid" />
          </>
        )}
      </RobotContainer>
    </Card>
  );
};
