import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import * as FullStory from '@fullstory/browser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { NextPage } from 'next';
import Link from 'next/link';
import { useEffect } from 'react';

import { Colors } from '@coco/ui-web';

import { RobotCard } from 'src/components/RobotCard';
import { Loader, MainButton, NoRobots } from '../components';
import { Container } from '../components/shared';
import PageContainer from '../components/shared/PageContainer';
import { useRobots } from '../hooks';
import { identifySegment } from '../lib/segment';

export const Card = styled.div(
  {
    backgroundColor: Colors.white,
    borderRadius: 10,
    boxShadow: '-2px 3px 13px 0px #0000001A',
    height: 40,
    marginBottom: 5,
    marginTop: 5,
    padding: 20,
    width: '100%',
  },
  ({ style }) => ({ ...style })
);

const RobotList: NextPage = () => {
  const { user } = useAuth0();
  const ldClient = useLDClient();

  const { data: robots, isFetching } = useRobots({});

  useEffect(() => {
    if (user?.sub) {
      FullStory.identify(user.sub);

      ldClient?.identify({ key: user.sub });

      identifySegment(user.sub, {
        auth_id: user.sub,
        email: user.email,
      });
    }
  }, [ldClient, user]);

  return (
    <PageContainer>
      <Container style={{ marginTop: 100 }}>
        {!robots?.length && !isFetching && <NoRobots />}
        <Link
          href={'/scan'}
          style={{
            alignSelf: !!robots?.length ? 'flex-end' : 'center',
            maxWidth: 300,
            width: '100%',
          }}
        >
          <MainButton title="Scan Robot" />
        </Link>
        {isFetching && <Loader style={{ marginBottom: 20 }} />}
        {!!robots?.length && robots.map((robot) => <RobotCard key={robot.serial} robot={robot} />)}
      </Container>
    </PageContainer>
  );
};

export default RobotList;
